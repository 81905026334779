import React from 'react'
import { graphql } from 'gatsby'
import { Container,Grid,Inner} from '../components/responsive/'
import H1 from '../components/h1'
import H2 from '../components/h2'
import Content from '../components/content'
import Image from '../components/image'
import Header from '../components/header/header'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Wrap from '../components/wrap'
import Metadata from '../components/metadata'
import './index.css'

export default ({ data }) => (
      <div>
      <Metadata title='開催場所'/>
      <Wrap>
      <Header active='venue'/>
      <Container backgroundColor='#FFF' paddingTop='1em' marginTop='4.5em'>
      <H1 fluid={data.venue.childImageSharp.fluid} info='biz BEACH CoWorking'>
      開催場所
      </H1>
      <Inner marginTop='2em'>
        <Content>
Drupal Camp DEN は東京都大田区にある展示場、会議室、経営・技術支援機能を備えた大田区産業プラザPioのA会議室・B会議・ 2F biz BEACH CoWorking の3会場です。<br/>
大田区産業プラザPioは、東京都の城南地域における中小企業支援拠点として、地域産業の活性化を目的として、ホール・会議室の貸施設や、中小企業を支援する各種支援機関・団体の事務所や窓口があります。<br/>
biz BEACH CoWorkingはワーキングスペースとしての役割だけでなく、イノベーションハブ機能を持つ次世代型スペースとして、企業家・経営者・これから起業される方をサポートする新しいカタチのコワーキングサロンです。
        </Content>
        <Content>
  太田区産業プラザPio <a href='https://www.pio-ota.net/facilities-index/' target='_blank' rel="noopener noreferrer">https://www.pio-ota.net/facilities-index/</a><br/>
  biz BEACH CoWorking <a href='https://biz BEACH.jp' target='_blank' rel="noopener noreferrer">https://biz BEACH.jp</a>
        </Content>
      </Inner>
      <H2>
      アクセス
      </H2>
      <Content>
大田区産業プラザPioは品川駅から電車で約6分、羽田空港から電車で約10分という、遠方からのアクセスもよい場所にあります。
      </Content>
      <Content>
      最寄駅<br/>
      ■京急蒲田駅から、徒歩約3分<br/>
      ■JR蒲田駅から、徒歩約10分
      </Content>
      <Grid pc='8' pcOffset='2'>
        <Image fluid={data.venue2.childImageSharp.fluid} />
      </Grid>
      <ContainerFooter/>
      </Container>
      <Footer/>
      </Wrap>
      </div>
)

export const query = graphql`
  query {
    venue:file(relativePath: { eq: "開催場所1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    venue2:file(relativePath: { eq: "開催場所2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
